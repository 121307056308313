define('bgr-ember-json-api/services/meta-store/meta-attr', ['exports', 'bgr-ember-json-api/utils'], function (exports, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = metaAttr;

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  /**
   * Get the default value for a meta attribute key.
   *
   * @param  {Object} options Meta attribute options.
   * @param  {String} key     Meta attribute key.
   * @return {Mixed}          Meta attribute default value.
   */

  function getDefaultValue(options, key) {
    var defaultValue = null;

    if (typeof options.defaultValue === 'function') {
      defaultValue = Reflect.apply(options.defaultValue, options, [options, key]);
    } else if ((0, _utils.hasKey)(options, 'defaultValue')) {
      defaultValue = options.defaultValue;

      (true && !((typeof defaultValue === 'undefined' ? 'undefined' : _typeof(defaultValue)) !== 'object' || defaultValue === null) && Ember.assert('Non primitive default values are not supported because they are shared between all instances. If you would like to use a complex object as a default value please provide a function that returns the complex object.', (typeof defaultValue === 'undefined' ? 'undefined' : _typeof(defaultValue)) !== 'object' || defaultValue === null));
    }

    return defaultValue;
  }

  /**
   * Get the value for a meta attribute key.
   *
   * @param  {Meta}   meta Meta record.
   * @param  {String} key  Meta attribute key.
   * @return {Mixed}       Meta attribute value.
   */

  function getValue(meta, key) {
    var acknowledgedAttributes = meta.get('_acknowledgedAttributes');
    var attributes = meta.get('_attributes');
    var value = null;

    if ((0, _utils.hasKey)(attributes, key)) {
      value = attributes[key];
    } else if ((0, _utils.hasKey)(acknowledgedAttributes, key)) {
      value = acknowledgedAttributes[key];
    }

    return value;
  }

  /**
   * Check if a meta record has a value for a specific meta attribute key.
   *
   * @param  {Meta}    meta Meta record.
   * @param  {String}  key  Meta attribute key.
   * @return {Boolean}
   */

  function hasValue(meta, key) {
    return (0, _utils.hasKey)(meta.get('_attributes'), key) || (0, _utils.hasKey)(meta.get('_acknowledgedAttributes'), key);
  }

  /**
   * Create a meta attribute.
   *
   * @param  {Object}   [options = {}] Meta attribute options.
   * @return {Computed}                Meta attribute computed property.
   */

  function metaAttr() {
    var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    return Ember.computed({
      get: function get(key) {
        var value = void 0;

        if (hasValue(this, key)) {
          value = getValue(this, key);
        } else {
          value = getDefaultValue(options, key);
        }

        return value;
      },
      set: function set(key, value) {
        if (value !== getValue(this, key)) {
          var attributes = (0, _utils.copy)(this.get('_attributes'));

          attributes[key] = value;

          this.set('_attributes', attributes);
        }

        return value;
      }
    }).meta({ isAttribute: true });
  }
});