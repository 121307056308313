define("ember-keyboard/listeners/key-events", ["exports", "ember-keyboard/utils/listener-name", "ember-keyboard/fixtures/valid-keys"], function (_exports, _listenerName, _validKeys) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.keyDown = keyDown;
  _exports.keyPress = keyPress;
  _exports.keyUp = keyUp;
  var validateKeys = function validateKeys(keys) {
    keys.forEach(function (key) {
      if (_validKeys.default.indexOf(key) === -1) {
        /* eslint no-console: ["error", { allow: ["error"] }] */
        console.error("`".concat(key, "` is not a valid key name"));
      }
    });
  };
  var formattedListener = function formattedListener(type, keysString) {
    var keys = keysString !== undefined ? keysString.split('+') : [];
    validateKeys(keys);
    return (0, _listenerName.default)(type, keys);
  };
  function keyDown(keys) {
    return formattedListener('keydown', keys);
  }
  function keyPress(keys) {
    return formattedListener('keypress', keys);
  }
  function keyUp(keys) {
    return formattedListener('keyup', keys);
  }
});