define('bgr-ember-json-api/serializer/private-api', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    /**
     * Methods
     */

    _createPaginationMetaData: function _createPaginationMetaData(normalized) {
      var links = normalized.links,
          meta = normalized.meta;


      return {
        activePage: this._extractPageFromLink(links.self, 1),
        firstPage: this._extractPageFromLink(links.first),
        lastPage: this._extractPageFromLink(links.last),
        nextPage: this._extractPageFromLink(links.next),
        perPage: meta.per_page,
        previousPage: this._extractPageFromLink(links.previous),
        totalRecords: meta.total
      };
    },
    _extractPageFromLink: function _extractPageFromLink(link) {
      var defaultValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

      if (!link) {
        return defaultValue;
      }

      var query = link.split('?')[1];

      if (!query) {
        return defaultValue;
      }

      var params = query.split('&');
      var length = params.length;

      var page = void 0;

      for (var i = 0; i < length; i++) {
        var paramStr = params[i];
        var paramArr = paramStr.split('=');
        var paramKey = paramArr[0];
        var paramVal = paramArr[1];

        if (paramKey === 'page') {
          page = parseInt(paramVal, 10);

          break;
        }
      }

      return page;
    }
  });
});