define('bgr-ember-modal/services/bgr-modal/public-api', ['exports', 'bgr-ember-modal/services/bgr-modal/modal'], function (exports, _modal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function assertion(key, value) {
    (true && !(value) && Ember.assert('The \'' + key + '\' parameter is required.', value));
  }

  exports.default = Ember.Mixin.create({
    /**
     * state
     */

    modalStack: Ember.A([]),
    upperModal: Ember.computed.readOnly('modalStack.lastObject'),

    /**
     * methods
     */

    add: function add() {
      return this.create.apply(this, arguments).add();
    },
    alert: function alert(message) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      assertion('message', message);

      return this.add('alert', null, Object.assign(options, { message: message }));
    },
    confirm: function confirm(message) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      assertion('message', message);

      return this.add('confirm', null, Object.assign(options, { message: message }));
    },
    create: function create(name) {
      var model = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      assertion('name', name);

      var service = this;
      var modal = _modal.default.create({
        model: model,
        name: name,
        options: options,
        service: service
      });

      return modal;
    },
    hide: function hide(name) {
      this.get('modalStack').slice().reverse().forEach(function (modal) {
        if (modal.get('name') === name) {
          modal.hide();
        }
      });

      return this;
    },
    hideAll: function hideAll() {
      this.get('modalStack').slice().reverse().forEach(function (modal) {
        modal.hide();
      });

      return this;
    },
    hideUpper: function hideUpper() {
      var upperModal = this.get('upperModal');

      if (upperModal) {
        upperModal.hide();
      }

      return this;
    },
    show: function show() {
      return this.create.apply(this, arguments).show();
    }
  });
});