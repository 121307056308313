define('bgr-ember-range-input/components/bgr-range-input', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  var assert = Ember.assert,
      Component = Ember.Component,
      computed = Ember.computed,
      observer = Ember.observer,
      next = Ember.run.next;


  var HAS_NATIVE_SUPPORT = function () {
    var input = document.createElement('input');

    input.setAttribute('type', 'range');

    return input.type === 'range';
  }();

  var OBSERVABLE_OPTIONS = ['isDisabled', 'max', 'min', 'step'];

  exports.default = Component.extend({
    /**
     * Props
     */

    baseClass: 'range-input',
    isDisabled: false,
    max: 100,
    min: 0,
    name: null,
    onChange: null,
    onInput: null,
    orientation: 'horizontal',
    step: 1,
    useNative: false,
    value: 0,

    /**
     * State
     */

    attributeBindings: ['isDisabled:disabled', 'max', 'min', 'name', 'orientation:data-orientation', 'step', 'type'],
    tagName: 'input',
    type: 'range',
    valueCopied: false,
    valueSent: false,

    /**
     * Computed
     */

    isNative: computed('useNative', function () {
      return this.get('useNative') && HAS_NATIVE_SUPPORT;
    }),

    /**
     * Hooks
     */

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.copyValue(false);

      if (this.get('isNative')) {
        this.setupNative();
      } else {
        this.setupPlugin();
        this.addOptionObservers();
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (this.get('isNative')) {
        this.destroyNative();
      } else {
        this.destroyPlugin();
        this.removeOptionObservers();
      }
    },


    /**
     * Observers
     */

    valueObserver: observer('value', function () {
      this.copyValue(true);
    }),

    /**
     * Methods
     */

    addOptionObservers: function addOptionObservers() {
      var _this = this;

      OBSERVABLE_OPTIONS.forEach(function (option) {
        _this.addObserver(option, _this, function () {
          _this.destroyPlugin();
          _this.$().css('opacity', 0);
          next(function () {
            return _this.setupPlugin();
          });
        });
      });
    },
    copyValue: function copyValue(valueCopied) {
      if (this.get('valueSent')) {
        this.set('valueSent', false);
      } else {
        var value = this.get('value');
        var type = typeof value === 'undefined' ? 'undefined' : _typeof(value);

        assert('Type of \'value\' should be \'number\' but was \'' + type + '\'', type === 'number');

        this.set('valueCopied', valueCopied);

        this.$().val(value).change();
      }
    },
    destroyNative: function destroyNative() {
      this.$().off('change input');
    },
    destroyPlugin: function destroyPlugin() {
      this.$().rangeslider('destroy');
    },
    removeOptionObservers: function removeOptionObservers() {
      var _this2 = this;

      OBSERVABLE_OPTIONS.forEach(function (option) {
        return _this2.removeObserver(option);
      });
    },
    sendChangeAction: function sendChangeAction(value) {
      if (this.get('isNative') && this.get('valueCopied')) {
        this.set('valueCopied', false);
      } else {
        this.sendAction('onChange', value);
      }
    },
    sendInputAction: function sendInputAction(value) {
      if (value !== this.get('value')) {
        this.set('valueSent', true);
        this.sendAction('onInput', value);
      }
    },
    setupNative: function setupNative() {
      var _this3 = this;

      var $element = this.$();

      $element.addClass(this.get('baseClass'));

      $element.on('change', function () {
        _this3.sendChangeAction(Number($element.val()));
      });

      $element.on('input', function () {
        _this3.sendInputAction(Number($element.val()));
      });
    },
    setupPlugin: function setupPlugin() {
      var _this4 = this;

      var baseClass = this.get('baseClass');

      this.$().rangeslider({
        activeClass: 'is-active',
        disabledClass: 'is-disabled',
        fillClass: baseClass + '__fill',
        handleClass: baseClass + '__handle',
        horizontalClass: baseClass + '--horizontal',
        polyfill: this.get('useNative'),
        rangeClass: baseClass,
        verticalClass: baseClass + '--vertical',
        onSlide: function onSlide(position, value) {
          return _this4.sendInputAction(value);
        },
        onSlideEnd: function onSlideEnd(position, value) {
          return _this4.sendChangeAction(value);
        }
      });
    }
  });
});