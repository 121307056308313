define('bgr-ember-json-api/model/debug', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = debug;

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  function assertEventWasCalled(eventName) {
    var _ref;

    var EVENT_NAME = Ember.String.underscore(eventName).toUpperCase();
    var EVENT_WAS_CALLED = EVENT_NAME + '_WAS_CALLED';
    var ASSERT_EVENT_WAS_CALLED = 'ASSERT_' + EVENT_NAME + '_WAS_CALLED';

    return _ref = {}, _defineProperty(_ref, eventName, function () {
      this._super.apply(this, arguments);

      this[EVENT_WAS_CALLED] = true;
    }), _defineProperty(_ref, ASSERT_EVENT_WAS_CALLED, Ember.on(eventName, function () {
      (true && !(this[EVENT_WAS_CALLED]) && Ember.assert('You must call \'this._super(...arguments);\' when overriding \'' + eventName + '\' on a BgrModel class. Please update ' + this + ' to call \'this._super(...arguments);\' from \'' + eventName + '\'.', this[EVENT_WAS_CALLED]));
    })), _ref;
  }

  function debug(Model) {
    Ember.runInDebug(function () {
      Model.reopen(assertEventWasCalled('didCreate'), assertEventWasCalled('didUpdate'), assertEventWasCalled('ready'));
    });
  }
});