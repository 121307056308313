define("ember-keyboard/fixtures/code-map", ["exports", "ember-keyboard/utils/generate-code-map"], function (_exports, _generateCodeMap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var platform,
    product = '';
  if (typeof FastBoot === 'undefined') {
    platform = navigator.platform;
    product = navigator.product;
  }
  var _default = (0, _generateCodeMap.default)(platform, product);
  _exports.default = _default;
});