define('bgr-ember-json-api/services/meta-store/meta-array', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.ArrayProxy.extend({
    /**
     * Key of the relationship linked to this meta array.
     *
     * @type {String}
     */

    _relationshipKey: null,

    /**
     * Resource record linked to this meta array.
     *
     * @type {Model}
     */

    _resource: null,

    /**
     * Array that contains all meta records.
     *
     * @type {Array}
     */

    content: null,

    /**
     * Indicates if the meta array has dirty meta records.
     *
     * @return {Boolean}
     */

    hasDirtyMeta: Ember.computed('content.@each.hasDirtyAttributes', function () {
      return this.isAny('hasDirtyAttributes');
    }),

    /**
     * Commit all meta records after a successful save.
     * If a meta relationship id is provided, save was called on a single meta record.
     *
     * @param  {String}    metaRelationshipId Meta relationship id.
     * @return {MetaArray}                    Meta array.
     */

    _commitMeta: function _commitMeta(metaRelationshipId) {
      this.forEach(function (meta) {
        var acknowledgedAttributes = void 0;

        if (metaRelationshipId) {
          if (metaRelationshipId === meta.get('_relationshipId')) {
            acknowledgedAttributes = meta.get('_snapshot');
          } else {
            acknowledgedAttributes = meta.get('_acknowledgedAttributes');
          }
        } else {
          acknowledgedAttributes = meta.toJSON();
        }

        meta._commit(acknowledgedAttributes);
      });

      return this;
    },


    /**
     * Find a specific meta record by relationship id.
     *
     * @param  {Number|String} relationshipId Relationship id.
     * @return {Meta}                         Meta record.
     */

    findMeta: function findMeta(relationshipId) {
      return this.findBy('_relationshipId', String(relationshipId));
    },


    /**
     * Rollback all meta records.
     *
     * @return {MetaArray} Meta array.
     */

    rollbackMeta: function rollbackMeta() {
      this.forEach(function (meta) {
        return meta.rollbackAttributes();
      });

      return this;
    },


    /**
     * Save all meta records.
     *
     * @return {Promise} Promise resolves with meta array.
     */

    save: function save() {
      var _this = this;

      return this.get('_resource').saveRelationship(this.get('_relationshipKey')).then(function () {
        return _this;
      });
    }
  });
});