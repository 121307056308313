define('bgr-ember-json-api/model/index', ['exports', 'ember-data/model', 'bgr-ember-json-api/model/public-api', 'bgr-ember-json-api/model/private-api', 'bgr-ember-json-api/model/debug'], function (exports, _model, _publicApi, _privateApi, _debug) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Model = _model.default.extend(_publicApi.default, _privateApi.default);

  (0, _debug.default)(Model);

  exports.default = Model;
});