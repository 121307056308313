define('bgr-ember-json-api/adapter/public-api', ['exports', 'bgr-ember-json-api/utils'], function (exports, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    /**
     * Hooks
     */

    urlForUpdateRecord: function urlForUpdateRecord(id, modelName, snapshot) {
      var urlForUpdateRecord = this._super.apply(this, arguments);
      var relationshipKey = (0, _utils.getAdapterOption)('relationshipKey', snapshot);

      if (relationshipKey) {
        return urlForUpdateRecord + '/relationships/' + relationshipKey;
      }

      return urlForUpdateRecord;
    }
  });
});