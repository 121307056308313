define("ember-keyboard/mixins/ember-keyboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create(Ember.Evented, {
    keyboardPriority: 0,
    keyboard: Ember.inject.service(),
    init: function init() {
      Ember.get(this, 'keyboard').register(this);
      return this._super.apply(this, arguments);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      Ember.get(this, 'keyboard').unregister(this);
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      Ember.get(this, 'keyboard').unregister(this);
    }
  });
  _exports.default = _default;
});