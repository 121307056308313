define('bgr-ember-json-api/transforms/json', ['exports', 'ember-data/transform'], function (exports, _transform) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _transform.default.extend({
    deserialize: function deserialize(serialized) {
      var deserialized = serialized;

      try {
        deserialized = JSON.parse(serialized);
      } catch (e) {
        window.console.warn('bgr-ember-json-api: Could not transform \'' + serialized + '\' to Object.');
      }

      return deserialized;
    },
    serialize: function serialize(deserialized) {
      var serialized = deserialized;

      if (deserialized === Object(deserialized)) {
        try {
          serialized = JSON.stringify(deserialized);
        } catch (e) {
          window.console.warn('bgr-ember-json-api: Could not transform \'' + deserialized + '\' to String.');
        }
      }

      return serialized;
    }
  });
});