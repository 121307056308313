define('bgr-ember-cropper/mixins/bgr-cropper-resize-support', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    /**
     * Hooks
     */

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.resizeHandler = function () {
        return Ember.run.debounce(_this, _this.resize, 200);
      };
    },


    /**
     * Observers
     */

    isActiveObserver: Ember.observer('isActive', function () {
      if (this.get('isActive')) {
        window.addEventListener('resize', this.resizeHandler);
      } else {
        window.removeEventListener('resize', this.resizeHandler);
      }
    }),

    /**
     * Methods
     */

    clearInstanceDimensions: function clearInstanceDimensions() {
      var cropper = this.get('instance.cropper');

      cropper.style.width = 'auto';
      cropper.style.height = 'auto';
    },
    resize: function resize() {
      this.clearInstanceDimensions();
      this.resizeInstance();
    },


    resizeHandler: null
  });
});