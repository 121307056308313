define("ember-composable-helpers/helpers/next", ["exports", "ember-composable-helpers/utils/get-index", "ember-composable-helpers/-private/create-needle-haystack-helper"], function (_exports, _getIndex, _createNeedleHaystackHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.next = next;
  function next(currentValue, array) {
    var useDeepEqual = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    var currentIndex = (0, _getIndex.default)(array, currentValue, useDeepEqual);
    var lastIndex = Ember.get(array, 'length') - 1;
    if (Ember.isEmpty(currentIndex)) {
      return;
    }
    return currentIndex === lastIndex ? currentValue : Ember.A(array).objectAt(currentIndex + 1);
  }
  var _default = (0, _createNeedleHaystackHelper.default)(next);
  _exports.default = _default;
});