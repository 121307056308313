define('bgr-ember-addon-blueprint/utils/merge-config', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = mergeConfig;
  function mergeConfig(t, s) {
    var target = t || {};
    var source = s || {};

    Object.keys(source).forEach(function (key) {
      var targetValue = target[key];
      var sourceValue = source[key];

      (true && !(typeof targetValue !== 'undefined') && Ember.assert('\'' + key + '\' is not a valid config key.', typeof targetValue !== 'undefined'));


      if (targetValue === Object(targetValue) && sourceValue === Object(sourceValue)) {
        mergeConfig(targetValue, sourceValue);
      } else {
        target[key] = sourceValue;
      }
    });

    return target;
  }
});