define('bgr-ember-modal/mixins/bgr-modal-controller-mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    /**
     * injections
     */

    bgrModal: Ember.inject.service(),

    /**
     * props
     */

    modalQueryParams: null,

    /**
     * state
     */

    _cachePerQueryParam: null,

    /**
     * hooks
     */

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var modalQueryParams = this.get('modalQueryParams');

      if (modalQueryParams !== Object(modalQueryParams)) {
        return;
      }

      var queryParams = this.get('queryParams');
      var cachePerQueryParam = {};

      Object.keys(modalQueryParams).forEach(function (queryParam) {
        (true && !(queryParams.includes(queryParam)) && Ember.assert('\'' + queryParam + '\' should also be included in \'queryParams\'.', queryParams.includes(queryParam)));


        cachePerQueryParam[queryParam] = {
          defaultValue: _this.get(queryParam),
          modal: null
        };

        _this.addObserver(queryParam, _this, _this._handleQueryParamChange);
      });

      this.set('_cachePerQueryParam', cachePerQueryParam);
    },


    /**
     * methods
     */

    _handleQueryParamChange: function _handleQueryParamChange(controller, queryParam) {
      var _this2 = this;

      var queryParamValue = this.get(queryParam);
      var queryParamCache = this.get('_cachePerQueryParam')[queryParam];
      var queryParamModalName = this.get('modalQueryParams')[queryParam];
      var queryParamDefaultValue = queryParamCache.defaultValue;
      var queryParamModal = queryParamCache.modal;

      if (queryParamValue !== queryParamDefaultValue) {
        queryParamModal = this.get('bgrModal').create(queryParamModalName, {}, {
          queryParam: queryParamValue
        });

        queryParamCache.modal = queryParamModal;

        queryParamModal.one('hide', function () {
          queryParamCache.modal = null;
          _this2.set(queryParam, queryParamDefaultValue);
        });

        queryParamModal.show();
      } else if (queryParamModal) {
        queryParamModal.hide();
      }
    }
  });
});