define('bgr-ember-numeric-input/components/bgr-numeric-input', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var isPresent = _ember['default'].isPresent;
  var observer = _ember['default'].observer;
  var on = _ember['default'].on;
  var next = _ember['default'].run.next;

  function round(number, precision) {
    var factor = Math.pow(10, precision);

    return Math.round(number * factor) / factor;
  }

  function unFloat(number, precision) {
    return Math.round(number * Math.pow(10, precision));
  }

  exports['default'] = Component.extend({
    /**
     * Options
     */

    isDisabled: false,
    isNullable: false,
    max: null,
    min: null,
    name: null,
    onChange: null,
    onInput: null,
    shiftKeyStep: 10,
    step: 1,
    value: null,

    /**
     * State
     */

    attributeBindings: ['isDisabled:disabled', 'name'],
    cachedValue: null,
    classNames: ['input', 'input--numeric'],
    keyActions: {
      13: 'enter',
      27: 'cancel',
      38: 'increment',
      40: 'decrement'
    },
    tagName: 'input',

    /**
     * Events
     */

    changeHandler: on('change', function () {
      this.update(this.serialize(this.get('element.value')), true);
    }),

    didInsertElementHandler: on('didInsertElement', function () {
      this.update(this.get('value'));
    }),

    keyDownHandler: on('keyDown', function (event) {
      var keyAction = this.get('keyActions')[event.keyCode];

      if (keyAction) {
        event.preventDefault();
      }
    }),

    keyUpHandler: on('keyUp', function (event) {
      var keyAction = this.get('keyActions')[event.keyCode];

      if (keyAction) {
        this[keyAction](event);
      }
    }),

    /**
     * Observers
     */

    valueObserver: observer('value', function () {
      var _this = this;

      next(function () {
        return _this.update(_this.get('value'));
      });
    }),

    /**
     * Methods
     */

    applyAdjustment: function applyAdjustment(type, event) {
      var step = event.shiftKey ? this.get('shiftKeyStep') : this.get('step');
      var precision = Number.isInteger(step) ? 0 : step.toString().split('.')[1].length;

      var method = type === 'increment' ? 'floor' : 'ceil';
      var adjustment = type === 'increment' ? step : -step;

      var unFloatedStep = unFloat(step, precision);
      var unFloatedCachedValue = unFloat(this.get('cachedValue'), precision);

      var input = round(Math[method](unFloatedCachedValue / unFloatedStep) * step + adjustment, precision);

      this.update(input);
    },

    blur: function blur() {
      this.get('element').blur();
    },

    cancel: function cancel() {
      this.update(this.get('cachedValue'));
      this.blur();
    },

    decrement: function decrement(event) {
      this.applyAdjustment('decrement', event);
    },

    deserialize: function deserialize(serialized) {
      return serialized;
    },

    enter: function enter() {
      this.update(this.serialize(this.get('element.value')), true);
      this.blur();
    },

    increment: function increment(event) {
      this.applyAdjustment('increment', event);
    },

    parseInput: function parseInput(input) {
      var min = this.get('min');
      var max = this.get('max');
      var isNullable = this.get('isNullable');

      var result = parseFloat(input, 10);

      if (isNaN(result)) {
        if (isNullable) {
          result = null;
        } else if (isPresent(min)) {
          result = min;
        } else if (isPresent(max) && max > 0) {
          result = 0;
        } else {
          result = null;
        }
      } else {
        if (isPresent(min) && result < min) {
          result = min;
        }

        if (isPresent(max) && result > max) {
          result = max;
        }
      }

      return result;
    },

    serialize: function serialize(deserialized) {
      return deserialized;
    },

    update: function update(input) {
      var triggerOnChangeAction = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];

      var element = this.get('element');
      var newValue = this.parseInput(input);
      var cachedLength = element.value.length;
      var selectionEnd = element.selectionEnd;

      element.value = this.deserialize(newValue);

      var newLength = element.value.length;

      var selectionPosition = undefined;

      if (newLength === cachedLength) {
        selectionPosition = selectionEnd;
      } else {
        selectionPosition = newLength;
      }

      element.setSelectionRange(selectionPosition, selectionPosition);

      if (newValue !== this.get('cachedValue')) {
        this.set('cachedValue', newValue);
        this.sendAction('onInput', newValue);
      }

      if (triggerOnChangeAction) {
        this.sendAction('onChange', newValue);
      }
    }
  });
});