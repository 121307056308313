define("ember-keyboard/mixins/keyboard-first-responder-on-focus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    makeFirstResponderOnFocusIn: Ember.on('click', 'focusIn', function () {
      Ember.setProperties(this, {
        keyboardActivated: true,
        keyboardFirstResponder: true
      });
    }),
    resignFirstResponderOnFocusOut: Ember.on('focusOut', function () {
      Ember.set(this, 'keyboardFirstResponder', false);
    })
  });
  _exports.default = _default;
});