define('bgr-ember-json-api/utils', ['exports', 'bgr-ember-json-api/services/meta-store/meta-model'], function (exports, _metaModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.camelizeObject = camelizeObject;
  exports.copy = copy;
  exports.deepAssign = deepAssign;
  exports.getAdapterOption = getAdapterOption;
  exports.getRelationship = getRelationship;
  exports.hasKey = hasKey;
  exports.isBelongsToRelationship = isBelongsToRelationship;
  exports.isHasManyRelationship = isHasManyRelationship;
  exports.isSerializable = isSerializable;
  exports.relationshipHasMetaModel = relationshipHasMetaModel;
  exports.isMetaRelationship = isMetaRelationship;
  exports.underscoreObject = underscoreObject;


  function modifyObject(object, modifier) {
    var modifiedObject = {};

    Object.keys(object).forEach(function (key) {
      modifiedObject[modifier(key)] = object[key];
    });

    return modifiedObject;
  }

  function camelizeObject(object) {
    return modifyObject(object, Ember.String.camelize);
  }

  function copy(subject) {
    return Object.assign({}, subject);
  }

  function deepAssign(t, s) {
    var target = t || {};
    var source = s || {};

    Object.keys(source).forEach(function (key) {
      var targetValue = target[key];
      var sourceValue = source[key];

      if (targetValue === Object(targetValue) && sourceValue === Object(sourceValue)) {
        deepAssign(targetValue, sourceValue);
      } else {
        target[key] = sourceValue;
      }
    });

    return target;
  }

  function getAdapterOption(key, subject) {
    return subject.adapterOptions && subject.adapterOptions[key];
  }

  function getRelationship(resource, relationshipKey) {
    return Ember.get(resource.constructor, 'relationshipsByName').get(relationshipKey);
  }

  function hasKey(subject, key) {
    return Object.prototype.hasOwnProperty.call(subject, key);
  }

  function isBelongsToRelationship(relationship) {
    return relationship.kind === 'belongsTo';
  }

  function isHasManyRelationship(relationship) {
    return relationship.kind === 'hasMany';
  }

  // TODO: Remove `isSerializable` util when `shouldSerialize` option is removed.
  function isSerializable(subject) {
    var shouldSerialize = subject.options.shouldSerialize;

    Ember.deprecate('bgr-ember-json-api: The `shouldSerialize` option is deprecated. Use the `attrs` property of the corresponding serializer instead. For more info, visit: https://emberjs.com/api/ember-data/3.5/classes/DS.JSONAPISerializer/properties/attrs?anchor=attrs.', typeof shouldSerialize !== 'boolean', {
      id: 'bgr-ember-json-api.should-serialize-option',
      until: '1.0.0'
    });

    return typeof shouldSerialize === 'boolean' ? shouldSerialize : true;
  }

  function relationshipHasMetaModel(relationship) {
    return relationship.options.meta && relationship.options.meta.prototype instanceof _metaModel.default;
  }

  function isMetaRelationship(relationship) {
    return isHasManyRelationship(relationship) && relationshipHasMetaModel(relationship);
  }

  function underscoreObject(object) {
    return modifyObject(object, Ember.String.underscore);
  }
});