define("ember-composable-helpers/helpers/contains", ["exports", "ember-composable-helpers/-private/create-needle-haystack-helper", "ember-composable-helpers/utils/includes"], function (_exports, _createNeedleHaystackHelper, _includes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.contains = contains;
  _exports.default = void 0;
  function _contains(needle, haystack) {
    return (0, _includes.default)(Ember.A(haystack), needle);
  }
  function contains(needle, haystack) {
    if (!Ember.isArray(haystack)) {
      return false;
    }
    if (Ember.isArray(needle) && Ember.get(needle, 'length')) {
      return needle.reduce(function (acc, val) {
        return acc && _contains(val, haystack);
      }, true);
    }
    return _contains(needle, haystack);
  }
  var _default = (0, _createNeedleHaystackHelper.default)(contains);
  _exports.default = _default;
});