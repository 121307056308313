define('bgr-ember-json-api/index', ['exports', 'bgr-ember-json-api/adapter', 'bgr-ember-json-api/model', 'bgr-ember-json-api/serializer', 'bgr-ember-json-api/services/meta-store/meta-attr', 'bgr-ember-json-api/services/meta-store/meta-model'], function (exports, _adapter, _model, _serializer, _metaAttr, _metaModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.Serializer = exports.Model = exports.Meta = exports.Adapter = undefined;


  var Meta = {
    attr: _metaAttr.default,
    Model: _metaModel.default
  };

  exports.Adapter = _adapter.default;
  exports.Meta = Meta;
  exports.Model = _model.default;
  exports.Serializer = _serializer.default;
});