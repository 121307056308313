define("ember-composable-helpers/helpers/without", ["exports", "ember-composable-helpers/-private/create-needle-haystack-helper", "ember-composable-helpers/utils/includes"], function (_exports, _createNeedleHaystackHelper, _includes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.without = without;
  function contains(needle, haystack) {
    return (0, _includes.default)(Ember.A(haystack), needle);
  }
  function without(needle, haystack) {
    if (!Ember.isArray(haystack)) {
      return false;
    }
    if (Ember.isArray(needle) && Ember.get(needle, 'length')) {
      return haystack.reduce(function (acc, val) {
        return contains(val, needle) ? acc : acc.concat(val);
      }, []);
    }
    return Ember.A(haystack).without(needle);
  }
  var _default = (0, _createNeedleHaystackHelper.default)(without);
  _exports.default = _default;
});