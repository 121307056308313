define('bgr-ember-json-api/model/public-api', ['exports', 'bgr-ember-json-api/utils', 'bgr-ember-json-api/model/assertions'], function (exports, _utils, _assertions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    /**
     * Services
     */

    metaStore: Ember.inject.service(),

    /**
     * Relationship methods
     */

    addRecordToRelationship: function addRecordToRelationship(relationshipKey, relationshipRecord, relationshipMetaAttributes) {
      var relationship = (0, _utils.getRelationship)(this, relationshipKey);

      (0, _assertions.assertHasManyRelationship)(relationship, relationshipKey);
      (0, _assertions.assertRelationshipRecord)(relationshipRecord, 'addRecordToRelationship');

      var addMeta = (0, _utils.relationshipHasMetaModel)(relationship);

      this._addRecordToRelationship(relationshipKey, relationshipRecord, addMeta, relationshipMetaAttributes);

      return this;
    },
    addRecordsToRelationship: function addRecordsToRelationship(relationshipKey, relationshipRecords) {
      var _this = this;

      var relationship = (0, _utils.getRelationship)(this, relationshipKey);

      (0, _assertions.assertHasManyRelationship)(relationship, relationshipKey);

      var addMeta = (0, _utils.relationshipHasMetaModel)(relationship);

      relationshipRecords.forEach(function (relationshipRecord) {
        (0, _assertions.assertRelationshipRecord)(relationshipRecord, 'addRecordsToRelationship');

        _this._addRecordToRelationship(relationshipKey, relationshipRecord, addMeta);
      });

      return this;
    },
    deleteRelationship: function deleteRelationship(relationshipKey) {
      var relationship = (0, _utils.getRelationship)(this, relationshipKey);

      (0, _assertions.assertRelationship)(relationship, relationshipKey);

      if ((0, _utils.isHasManyRelationship)(relationship)) {
        this.get(relationshipKey).clear();
        this.get('metaStore').destroyMetaArray(this, relationshipKey);
      } else {
        this.set(relationshipKey, null);
      }

      return this;
    },
    destroyRecordFromRelationship: function destroyRecordFromRelationship(relationshipKey, relationshipRecord) {
      return this.removeRecordFromRelationship(relationshipKey, relationshipRecord).saveRelationship(relationshipKey);
    },
    destroyRecordsFromRelationship: function destroyRecordsFromRelationship(relationshipKey, relationshipRecords) {
      return this.removeRecordsFromRelationship(relationshipKey, relationshipRecords).saveRelationship(relationshipKey);
    },
    destroyRelationship: function destroyRelationship(relationshipKey) {
      return this.deleteRelationship(relationshipKey).saveRelationship(relationshipKey);
    },
    isRelationshipDirty: function isRelationshipDirty(relationshipKey) {
      var relationship = (0, _utils.getRelationship)(this, relationshipKey);

      (0, _assertions.assertRelationship)(relationship, relationshipKey);

      return this._isRelationshipDirty(relationship);
    },
    removeRecordFromRelationship: function removeRecordFromRelationship(relationshipKey, relationshipRecord) {
      var relationship = (0, _utils.getRelationship)(this, relationshipKey);

      (0, _assertions.assertHasManyRelationship)(relationship, relationshipKey);
      (0, _assertions.assertRelationshipRecord)(relationshipRecord, 'removeRecordFromRelationship');

      this._removeRecordFromRelationship(relationshipKey, relationshipRecord);

      return this;
    },
    removeRecordsFromRelationship: function removeRecordsFromRelationship(relationshipKey, relationshipRecords) {
      var _this2 = this;

      var relationship = (0, _utils.getRelationship)(this, relationshipKey);

      (0, _assertions.assertHasManyRelationship)(relationship, relationshipKey);

      relationshipRecords.forEach(function (relationshipRecord) {
        (0, _assertions.assertRelationshipRecord)(relationshipRecord, 'removeRecordsFromRelationship');

        _this2._removeRecordFromRelationship(relationshipKey, relationshipRecord);
      });

      return this;
    },


    /**
     * Meta methods
     */

    findAllMeta: function findAllMeta(relationshipKey) {
      (0, _assertions.assertResourceRecord)(this, 'findAllMeta');
      (0, _assertions.assertMetaRelationship)(this, relationshipKey);

      return this.get('metaStore').findAllMeta(this, relationshipKey);
    },
    findMeta: function findMeta(relationshipKey, relationshipId) {
      (0, _assertions.assertResourceRecord)(this, 'findMeta');
      (0, _assertions.assertMetaRelationship)(this, relationshipKey);

      return this.get('metaStore').findMeta(this, relationshipKey, relationshipId);
    },
    peekAllMeta: function peekAllMeta(relationshipKey) {
      (0, _assertions.assertMetaRelationship)(this, relationshipKey);

      return this.get('metaStore').peekAllMeta(this, relationshipKey);
    },
    peekMeta: function peekMeta(relationshipKey, relationshipId) {
      (0, _assertions.assertMetaRelationship)(this, relationshipKey);

      return this.get('metaStore').peekMeta(this, relationshipKey, relationshipId);
    },


    /**
     * Save methods
     */

    saveRelationship: function saveRelationship(relationshipKey, saveOptions) {
      var _this3 = this;

      var relationship = (0, _utils.getRelationship)(this, relationshipKey);

      (0, _assertions.assertRelationship)(relationship, relationshipKey);
      (true && !(this._canSerializeRelationship(relationship)) && Ember.assert('Invalid relationship. \'' + relationshipKey + '\' must be a serializable relationship.', this._canSerializeRelationship(relationship)));


      var extendedSaveOptions = (0, _utils.deepAssign)({ adapterOptions: { relationshipKey: relationshipKey } }, saveOptions);

      if ((0, _utils.isMetaRelationship)(relationship)) {
        return this.findAllMeta(relationshipKey).then(function (metaArray) {
          return _this3.save(extendedSaveOptions).then(function () {
            var metaRelationshipId = (0, _utils.getAdapterOption)('metaRelationshipId', extendedSaveOptions);

            metaArray._commitMeta(metaRelationshipId);

            return _this3;
          });
        });
      }

      return this.save(extendedSaveOptions);
    },
    saveRelationships: function saveRelationships() {
      var _this4 = this;

      var _determineArgumentsFo = this._determineArgumentsForRelationshipMethod.apply(this, arguments),
          relationshipKeys = _determineArgumentsFo.relationshipKeys,
          saveOptions = _determineArgumentsFo.saveOptions;

      if (!relationshipKeys.length) {
        relationshipKeys = this.get('_serializableRelationshipKeys');
      }

      var promises = relationshipKeys.map(function (relationshipKey) {
        return _this4.saveRelationship(relationshipKey, saveOptions);
      });

      return Ember.RSVP.all(promises).then(function () {
        return _this4;
      });
    },
    saveWithRelationships: function saveWithRelationships() {
      var _this5 = this;

      var _determineArgumentsFo2 = this._determineArgumentsForRelationshipMethod.apply(this, arguments),
          relationshipKeys = _determineArgumentsFo2.relationshipKeys,
          saveOptions = _determineArgumentsFo2.saveOptions;

      if (this.get('isNew')) {
        return this.save(saveOptions);
      }

      var serializableHasManyRelationshipKeys = this.get('_serializableHasManyRelationshipKeys');

      if (relationshipKeys.length) {
        Ember.runInDebug(function () {
          var serializableBelongsToRelationshipKeys = _this5.get('_serializableBelongsToRelationshipKeys');

          relationshipKeys.forEach(function (relationshipKey) {
            var relationship = (0, _utils.getRelationship)(_this5, relationshipKey);

            (0, _assertions.assertRelationship)(relationship, relationshipKey);
            (true && !(_this5._canSerializeRelationship(relationship)) && Ember.assert('Invalid relationship. \'' + relationshipKey + '\' must be a serializable relationship.', _this5._canSerializeRelationship(relationship)));
            (true && !(!serializableBelongsToRelationshipKeys.includes(relationshipKey)) && Ember.assert('The \'' + relationshipKey + '\' relationship key should be ommited as \'belongsTo\' relationships are saved via the resource record.', !serializableBelongsToRelationshipKeys.includes(relationshipKey)));
          });
        });
      } else {
        relationshipKeys = serializableHasManyRelationshipKeys;
      }

      return this.saveRelationships(relationshipKeys).then(function () {
        return _this5.save(saveOptions);
      });
    }
  });
});