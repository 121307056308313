define('bgr-ember-json-api/serializer/public-api', ['exports', 'bgr-ember-json-api/utils'], function (exports, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    /**
     * Services
     */

    metaStore: Ember.inject.service(),

    /**
     * Hooks
     */

    keyForAttribute: function keyForAttribute(attr) {
      return Ember.String.underscore(attr);
    },
    normalizeQueryResponse: function normalizeQueryResponse() {
      var normalized = this._super.apply(this, arguments);

      if (normalized.links && normalized.links.first) {
        normalized.meta.pagination = this._createPaginationMetaData(normalized);
      }

      return normalized;
    },
    payloadKeyFromModelName: function payloadKeyFromModelName(modelName) {
      return Ember.String.classify(modelName);
    },
    payloadTypeFromModelName: function payloadTypeFromModelName(modelName) {
      return Ember.String.classify(modelName);
    },
    serialize: function serialize(snapshot) {
      // Get default serialized.
      var serialized = this._super.apply(this, arguments);

      // Get relationship key.
      var relationshipKey = (0, _utils.getAdapterOption)('relationshipKey', snapshot);

      // No relationship key means we're not saving a relationship,
      // return default serialized.
      if (!relationshipKey) {
        return serialized;
      }

      // Past this point we're saving a relationship.

      // Get serialized relationships.
      var relationships = serialized.data.relationships;

      // Return only the serialized relationship if available.
      if (relationships && relationships[relationshipKey]) {
        return relationships[relationshipKey];
      }

      // Past this point we tried saving a specific relationship,
      // but it was not serialized, this happens when the relationship is empty.

      // Get relationship.
      var relationship = (0, _utils.getRelationship)(snapshot.record, relationshipKey);

      // Empty serialized object.
      serialized = {};

      // Determine relationship type.
      if ((0, _utils.isHasManyRelationship)(relationship)) {
        // hasMany -> [].
        serialized.data = [];
      } else {
        // belongsTo -> null.
        serialized.data = null;
      }

      return serialized;
    },


    // TODO: Remove `serializeAttribute` hook when `shouldSerialize` option is removed.
    serializeAttribute: function serializeAttribute(snapshot, json, key, attribute) {
      if ((0, _utils.isSerializable)(attribute)) {
        this._super.apply(this, arguments);
      }
    },


    // TODO: Remove `serializeBelongsTo` hook when `shouldSerialize` option is removed.
    serializeBelongsTo: function serializeBelongsTo(snapshot, json, relationship) {
      if ((0, _utils.isSerializable)(relationship)) {
        this._super.apply(this, arguments);
      }
    },
    serializeHasMany: function serializeHasMany(snapshot, json, relationship) {
      this._super.apply(this, arguments);

      var relationships = json.relationships;
      var relationshipKey = relationship.key;

      if (relationships && relationships[relationshipKey] && (0, _utils.isMetaRelationship)(relationship)) {
        var record = snapshot.record;
        var metaArray = this.get('metaStore').peekAllMeta(record, relationshipKey);
        var metaRelationshipId = (0, _utils.getAdapterOption)('metaRelationshipId', snapshot);

        relationships[relationshipKey].data.forEach(function (relationshipData) {
          var relationshipId = relationshipData.id;
          var meta = metaArray.findMeta(relationshipId);

          (true && !(meta) && Ember.assert('Meta missing for \'' + relationshipKey + '\' relationship record with id \'' + relationshipId + '\'.', meta));


          var metaPayload = void 0;

          if (metaRelationshipId) {
            if (metaRelationshipId === meta.get('_relationshipId')) {
              metaPayload = meta.get('_snapshot');
            } else {
              metaPayload = meta.get('_acknowledgedAttributes');
            }
          } else {
            metaPayload = meta.toJSON();
          }

          relationshipData.meta = (0, _utils.underscoreObject)(metaPayload);
        });
      }
    },
    shouldSerializeHasMany: function shouldSerializeHasMany(snapshot, key, relationship) {
      // TODO: Remove `relationshipIsSerializable` variable when `shouldSerialize` option is removed.

      var recordIsNew = snapshot.record.get('isNew');
      var relationshipIsSerializable = (0, _utils.isSerializable)(relationship);
      var saveRelationship = (0, _utils.getAdapterOption)('relationshipKey', snapshot) === relationship.key;
      var shouldSerializeHasMany = this._super.apply(this, arguments);

      return recordIsNew && relationshipIsSerializable && shouldSerializeHasMany || saveRelationship;
    }
  });
});