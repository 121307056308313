define("ember-keyboard/services/keyboard", ["exports", "ember-keyboard/listeners/key-events", "ember-keyboard/utils/handle-key-event"], function (_exports, _keyEvents, _handleKeyEvent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    isPropagationEnabled: false,
    registeredResponders: Ember.computed(function () {
      return Ember.A();
    }),
    activeResponders: Ember.computed.filterBy('registeredResponders', 'keyboardActivated'),
    sortedRespondersSortDefinition: Ember.computed('isPropagationEnabled', function () {
      return Ember.get(this, 'isPropagationEnabled') ? ['keyboardPriority:desc'] : ['keyboardFirstResponder:desc', 'keyboardPriority:desc'];
    }),
    sortedResponders: Ember.computed.sort('activeResponders', 'sortedRespondersSortDefinition'),
    firstResponders: Ember.computed.filterBy('sortedResponders', 'keyboardFirstResponder'),
    normalResponders: Ember.computed.filter('sortedResponders.@each.keyboardFirstResponder', function (responder) {
      return !Ember.get(responder, 'keyboardFirstResponder');
    }),
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);
      if (typeof FastBoot !== 'undefined') {
        return;
      }
      var config = Ember.getOwner(this).resolveRegistration('config:environment') || {};
      var isPropagationEnabled = Boolean(Ember.get(config, 'emberKeyboard.propagation'));
      Ember.set(this, 'isPropagationEnabled', isPropagationEnabled);
      this._boundRespond = Ember.run.bind(this, this._respond);
      this._listeners = Ember.get(config, 'emberKeyboard.listeners') || ['keyUp', 'keyDown', 'keyPress'];
      this._listeners = this._listeners.map(function (listener) {
        return listener.toLowerCase();
      });
      this._listeners.forEach(function (type) {
        document.addEventListener(type, _this._boundRespond);
      });
    },
    willDestroy: function willDestroy() {
      var _this2 = this;
      this._super.apply(this, arguments);
      if (typeof FastBoot !== 'undefined') {
        return;
      }
      this._listeners.forEach(function (type) {
        document.removeEventListener(type, _this2._boundRespond);
      });
    },
    _respond: function _respond(event) {
      var _this3 = this;
      Ember.run(function () {
        if (Ember.get(_this3, 'isPropagationEnabled')) {
          (0, _handleKeyEvent.handleKeyEventWithPropagation)(event, Ember.getProperties(_this3, 'firstResponders', 'normalResponders'));
        } else {
          (0, _handleKeyEvent.handleKeyEventWithLaxPriorities)(event, Ember.get(_this3, 'sortedResponders'));
        }
      });
    },
    register: function register(responder) {
      Ember.get(this, 'registeredResponders').pushObject(responder);
    },
    unregister: function unregister(responder) {
      Ember.get(this, 'registeredResponders').removeObject(responder);
    },
    keyDown: function keyDown() {
      return _keyEvents.keyDown.apply(void 0, arguments);
    },
    keyPress: function keyPress() {
      return _keyEvents.keyPress.apply(void 0, arguments);
    },
    keyUp: function keyUp() {
      return _keyEvents.keyUp.apply(void 0, arguments);
    }
  });
  _exports.default = _default;
});