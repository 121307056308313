define('bgr-ember-pagination/components/bgr-pagination', ['exports', 'ember', 'bgr-ember-pagination/templates/components/bgr-pagination'], function (exports, _ember, _bgrEmberPaginationTemplatesComponentsBgrPagination) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    /**
     * Props
     */

    config: {},
    visiblePages: 7,

    /**
     * State
     */

    classNames: ['pagination'],
    layout: _bgrEmberPaginationTemplatesComponentsBgrPagination['default'],
    tagName: 'ul',

    /**
     * Computed
     */

    activePage: computed.readOnly('config.activePage'),
    firstPage: computed.readOnly('config.firstPage'),
    lastPage: computed.readOnly('config.lastPage'),
    nextPage: computed.readOnly('config.nextPage'),
    perPage: computed.readOnly('config.perPage'),
    previousPage: computed.readOnly('config.previousPage'),
    totalRecords: computed.readOnly('config.totalRecords'),

    isFirstPage: computed('activePage', 'firstPage', function () {
      return this.get('activePage') === this.get('firstPage');
    }),

    isLastPage: computed('activePage', 'lastPage', function () {
      return this.get('activePage') === this.get('lastPage');
    }),

    pages: computed('activePage', 'perPage', 'totalRecords', function () {
      var activePage = this.get('activePage');
      var perPage = this.get('perPage');
      var totalRecords = this.get('totalRecords');
      var visiblePages = this.get('visiblePages');

      var pages = [];
      var total = Math.ceil(totalRecords / perPage);
      var visible = Math.min(total, visiblePages);
      var half = Math.floor(visible / 2);

      var start = activePage - half + 1 - visible % 2;
      var end = activePage + half;

      if (start <= 0) {
        start = 1;
        end = visible;
      }

      if (end > total) {
        start = total - visible + 1;
        end = total;
      }

      for (var page = start; page <= end; page += 1) {
        pages.push(page);
      }

      return pages;
    })
  });
});