define('bgr-ember-modal/services/bgr-modal/private-api', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ACTIVE_CLASS = 'has-active-modal';
  var ESCAPE_KEY_CODE = 27;

  exports.default = Ember.Mixin.create({
    /**
     * hooks
     */

    init: function init() {
      this._super.apply(this, arguments);

      this._keyUpHandler = this._keyUpHandler.bind(this);
    },


    /**
     * observers
     */

    _modalStackObserver: Ember.observer('modalStack.length', function () {
      // eslint-disable-line
      if (this.get('modalStack.length') > 0) {
        document.addEventListener('keyup', this._keyUpHandler);
        document.querySelector('html').classList.add(ACTIVE_CLASS);
      } else {
        document.removeEventListener('keyup', this._keyUpHandler);
        document.querySelector('html').classList.remove(ACTIVE_CLASS);
      }
    }),

    /**
     * methods
     */

    _addModal: function _addModal(modal) {
      this.get('modalStack').addObject(modal);

      return this;
    },
    _hideModal: function _hideModal(modal) {
      this.get('modalStack').removeObject(modal);

      return this;
    },
    _keyUpHandler: function _keyUpHandler(event) {
      if (event.keyCode === ESCAPE_KEY_CODE) {
        this.hideUpper();
      }
    },
    _showModal: function _showModal(modal) {
      return this.hideAll()._addModal(modal);
    }
  });
});