define('bgr-ember-json-api/model/assertions', ['exports', 'bgr-ember-json-api/utils'], function (exports, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.assertHasManyRelationship = assertHasManyRelationship;
  exports.assertMetaRelationship = assertMetaRelationship;
  exports.assertRelationship = assertRelationship;
  exports.assertRelationshipRecord = assertRelationshipRecord;
  exports.assertResourceRecord = assertResourceRecord;


  function assertRecord(type, record, method) {
    (true && !(record.get('id')) && Ember.assert('Invalid ' + type + ' record. Record must have an id. Make sure to save it before calling \'' + method + '\'.', record.get('id')));
  }

  function assertHasManyRelationship(relationship, relationshipKey) {
    (true && !(relationship && (0, _utils.isHasManyRelationship)(relationship)) && Ember.assert('Invalid relationship. \'' + relationshipKey + '\' must be a \'hasMany\' relationship.', relationship && (0, _utils.isHasManyRelationship)(relationship)));
  }

  function assertMetaRelationship(resource, relationshipKey) {
    var relationship = (0, _utils.getRelationship)(resource, relationshipKey);

    (true && !(relationship && (0, _utils.isMetaRelationship)(relationship)) && Ember.assert('Invalid relationship. \'' + relationshipKey + '\' must be a \'hasMany\' relationship with a meta model defined.', relationship && (0, _utils.isMetaRelationship)(relationship)));
  }

  function assertRelationship(relationship, relationshipKey) {
    (true && !(relationship) && Ember.assert('Invalid relationship. \'' + relationshipKey + '\' must be a relationship.', relationship));
  }

  function assertRelationshipRecord() {
    assertRecord.apply(undefined, ['relationship'].concat(Array.prototype.slice.call(arguments)));
  }

  function assertResourceRecord() {
    assertRecord.apply(undefined, ['resource'].concat(Array.prototype.slice.call(arguments)));
  }
});