define("ember-keyboard/utils/get-mouse-name", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getMouseName;
  function getMouseName(buttonCode) {
    if (Ember.isNone(buttonCode)) return;
    switch (buttonCode) {
      case 0:
        return 'left';
      case 1:
        return 'middle';
      case 2:
        return 'right';
    }
  }
});