define('bgr-ember-json-api/model/private-api', ['exports', 'bgr-ember-json-api/utils'], function (exports, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    /**
     * State
     */

    CACHED_IDS_PER_RELATIONSHIP: null,

    /**
     * Computed
     */

    _hasManyMetaRelationshipKeys: Ember.computed(function () {
      var hasManyMetaRelationshipKeys = [];

      this.eachRelationship(function (relationshipKey, relationship) {
        if ((0, _utils.isMetaRelationship)(relationship)) {
          hasManyMetaRelationshipKeys.push(relationshipKey);
        }
      });

      return hasManyMetaRelationshipKeys;
    }),

    _serializableBelongsToRelationshipKeys: Ember.computed(function () {
      var _this = this;

      var serializableBelongsToRelationshipKeys = [];

      this.eachRelationship(function (relationshipKey, relationship) {
        if ((0, _utils.isBelongsToRelationship)(relationship) && _this._canSerializeRelationship(relationship)) {
          serializableBelongsToRelationshipKeys.push(relationshipKey);
        }
      });

      return serializableBelongsToRelationshipKeys;
    }),

    _serializableHasManyRelationshipKeys: Ember.computed(function () {
      var _this2 = this;

      var serializableHasManyRelationshipKeys = [];

      this.eachRelationship(function (relationshipKey, relationship) {
        if ((0, _utils.isHasManyRelationship)(relationship) && _this2._canSerializeRelationship(relationship)) {
          serializableHasManyRelationshipKeys.push(relationshipKey);
        }
      });

      return serializableHasManyRelationshipKeys;
    }),

    _serializableRelationshipKeys: Ember.computed(function () {
      return this.get('_serializableBelongsToRelationshipKeys').concat(this.get('_serializableHasManyRelationshipKeys'));
    }),

    /**
     * Hooks
     */

    didCreate: function didCreate() {
      this._super.apply(this, arguments);

      this._updateCachedIdsPerRelationship();
      this._commitHasManyMetaRelationships();
    },
    didUpdate: function didUpdate() {
      this._super.apply(this, arguments);

      this._updateCachedIdsPerRelationship();
    },
    ready: function ready() {
      this._super.apply(this, arguments);

      this._updateCachedIdsPerRelationship();
    },


    /**
     * Methods
     */

    _addRecordToRelationship: function _addRecordToRelationship(relationshipKey, relationshipRecord, addMeta, relationshipMetaAttributes) {
      var relationshipId = relationshipRecord.get('id');

      if (!this.hasMany(relationshipKey).ids().includes(relationshipId)) {
        this.get(relationshipKey).pushObject(relationshipRecord);

        if (addMeta) {
          this.get('metaStore').addMeta(this, relationshipKey, relationshipId, relationshipMetaAttributes);
        }
      }
    },
    _canSerializeRelationship: function _canSerializeRelationship(relationship) {
      // TODO: Remove `isSerializable` util when `shouldSerialize` option is removed.

      var serializer = this.get('store').serializerFor(this.constructor.modelName);

      return serializer._canSerialize(relationship.key) && (0, _utils.isSerializable)(relationship);
    },
    _commitHasManyMetaRelationships: function _commitHasManyMetaRelationships() {
      var _this3 = this;

      var metaStore = this.get('metaStore');

      this.get('_hasManyMetaRelationshipKeys').forEach(function (relationshipKey) {
        metaStore.peekAllMeta(_this3, relationshipKey)._commitMeta();
      });
    },
    _determineArgumentsForRelationshipMethod: function _determineArgumentsForRelationshipMethod() {
      for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      // Get the last argument.
      var lastArg = args[args.length - 1];

      // Determine 'saveOptions'.
      var saveOptions = lastArg === Object(lastArg) && !Array.isArray(lastArg) ? args.pop() : {};

      // Determine 'relationshipKeys'.
      var relationshipKeys = Array.isArray(args[0]) ? args[0] : args;

      return {
        relationshipKeys: relationshipKeys,
        saveOptions: saveOptions
      };
    },
    _isRelationshipDirty: function _isRelationshipDirty(relationship) {
      if ((0, _utils.isHasManyRelationship)(relationship)) {
        var relationshipIds = this.hasMany(relationship.key).ids();
        var cachedRelationshipIds = this.get('CACHED_IDS_PER_RELATIONSHIP')[relationship.key];

        return relationshipIds.length !== cachedRelationshipIds.length || relationshipIds.some(function (id) {
          return !cachedRelationshipIds.includes(id);
        });
      }

      var relationshipId = this.belongsTo(relationship.key).id();
      var cachedRelationshipId = this.get('CACHED_IDS_PER_RELATIONSHIP')[relationship.key];

      return relationshipId !== cachedRelationshipId;
    },
    _removeRecordFromRelationship: function _removeRecordFromRelationship(relationshipKey, relationshipRecord) {
      var relationshipId = relationshipRecord.get('id');

      if (this.hasMany(relationshipKey).ids().includes(relationshipId)) {
        this.get(relationshipKey).removeObject(relationshipRecord);
        this.get('metaStore').destroyMeta(this, relationshipKey, relationshipId);
      }
    },
    _updateCachedIdsPerRelationship: function _updateCachedIdsPerRelationship() {
      var _this4 = this;

      var cachedIdsPerRelationship = {};

      this.eachRelationship(function (relationshipKey, relationship) {
        if ((0, _utils.isHasManyRelationship)(relationship)) {
          cachedIdsPerRelationship[relationshipKey] = _this4.hasMany(relationshipKey).ids();
        } else {
          cachedIdsPerRelationship[relationshipKey] = _this4.belongsTo(relationshipKey).id();
        }
      });

      this.set('CACHED_IDS_PER_RELATIONSHIP', cachedIdsPerRelationship);
    }
  });
});