define("ember-data-storefront/components/assert-must-preload/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _toArray(arr) { return _arrayWithHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  /**
    _This component relies on JSON:API, and assumes that your server supports JSON:API includes._
  
    _{{assert-must-preload}} only works on models that have included the LoadableModel mixin._
  
    Use this when authoring a component that requires a model to be passed in with
    certain relationships already loaded.
  
    For example, if you wanted to ensure the following template was never rendered without `post.comments` already loaded, you could add the call to `{{assert-must-preload}}`:
  
    ```hbs
    {{assert-must-preload post 'comments.author'}}
  
    {{!-- the rest of your template --}}
    {{#each post.comments as |comment|}}
      This comment was written by {{comment.author.name}}
    {{/each}}
    ```
  
    If any developer ever tries to render this template without first loading the post's `comments.author`, they'll get a dev-time error.
  
    @class AssertMustPreload
    @public
  */
  var _default = Ember.Component.extend({
    tagName: '',
    didReceiveAttrs: function didReceiveAttrs() {
      var _this$get = this.get('args'),
        _this$get2 = _toArray(_this$get),
        model = _this$get2[0],
        includes = _this$get2.slice(1);
      var parentComponent = this.parentView;
      var parentName = parentComponent ? parentComponent._debugContainerKey : 'template';
      var includesString = includes.join(',');
      (true && !(model.hasLoaded) && Ember.assert("You passed a ".concat(model.constructor.modelName, " model into an {{assert-must-preload}}, but that model is not using the Loadable mixin. [ember-data-storefront]"), model.hasLoaded));
      (true && !(model.hasLoaded(includesString)) && Ember.assert("You tried to render a ".concat(parentName, " that accesses relationships off of a ").concat(model.constructor.modelName, ", but that model didn't have all of its required relationships preloaded ('").concat(includesString, "'). Please make sure to preload the association. [ember-data-storefront]"), model.hasLoaded(includesString)));
      return this._super.apply(this, arguments);
    }
  }).reopenClass({
    positionalParams: 'args'
  });
  _exports.default = _default;
});