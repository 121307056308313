var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _templateObject = _taggedTemplateLiteral(['template-compiler:main'], ['template-compiler:main']),
    _templateObject2 = _taggedTemplateLiteral(['template-options:main'], ['template-options:main']);

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

(function () {
  var P = Ember.__loader.require('container').privatize;
  var _Ember = Ember,
      Application = _Ember.Application,
      Component = _Ember.Component,
      computed = _Ember.computed,
      getOwner = _Ember.getOwner;

  var _Ember$__loader$requi = Ember.__loader.require(true ? '@glimmer/reference' : 'glimmer-reference'),
      combineTagged = _Ember$__loader$requi.combineTagged;

  var _Ember$__loader$requi2 = Ember.__loader.require(true ? '@glimmer/runtime' : 'glimmer-runtime'),
      clientBuilder = _Ember$__loader$requi2.clientBuilder;

  // This is entirely cribbed from the real ClassListReference in glimmer-vm.


  var ClassListReference = function () {
    function ClassListReference(list) {
      _classCallCheck(this, ClassListReference);

      this.tag = combineTagged(list);
      this.list = list;
    }

    _createClass(ClassListReference, [{
      key: 'value',
      value: function value() {
        var ret = [];
        var list = this.list;


        for (var i = 0; i < list.length; i++) {
          var value = this._normalizeStringValue(list[i].value());
          if (value) ret.push(value);
        }

        return ret.length === 0 ? null : ret.join(' ');
      }
    }, {
      key: '_normalizeStringValue',
      value: function _normalizeStringValue(value) {
        if (value === null || value === undefined || typeof value.toString !== 'function') {
          return '';
        }
        return String(value);
      }
    }]);

    return ClassListReference;
  }();

  var MergedAttributesReference = function () {
    function MergedAttributesReference(references) {
      _classCallCheck(this, MergedAttributesReference);

      this.references = references;

      var referencesArray = [];
      for (var reference in references) {
        referencesArray.push(references[reference]);
      }
      this.tag = combineTagged(referencesArray);
    }

    _createClass(MergedAttributesReference, [{
      key: 'value',
      value: function value() {
        var value = Object.create(null);
        for (var key in this.references) {
          value[key] = this.references[key].value();
        }
        return value;
      }
    }, {
      key: 'get',
      value: function get(property) {
        return this.references[property];
      }
    }]);

    return MergedAttributesReference;
  }();

  function mergeAttributesHelper(_vm, args) {
    var references = {};
    var classReferences = [];
    for (var i = 0; i < args.positional.length; i++) {
      var arg = args.positional.at(i);
      var snapshot = arg.value();
      if (snapshot) {
        var names = Object.keys(arg.value());
        for (var _i = 0; _i < names.length; _i++) {
          var name = names[_i];
          var reference = arg.get(name);
          if (name === 'class') {
            classReferences.push(reference);
          } else {
            references[name] = reference;
          }
        }
      }
    }
    if (classReferences.length > 1) {
      references['class'] = new ClassListReference(classReferences);
    } else if (classReferences.length > 0) {
      references['class'] = classReferences[0];
    }

    return new MergedAttributesReference(references);
  }

  if (false) {
    Application.reopenClass({
      buildRegistry: function buildRegistry() {
        var registry = this._super.apply(this, arguments);

        var compilerName = false ? P(_templateObject) : P(_templateObject2);
        var TemplateCompiler = registry.resolve(compilerName);

        registry.register('component-lookup:main', Ember.Object.extend({
          componentFor: function componentFor(name, owner, options) {
            var fullName = 'component:' + name;
            return owner.factoryFor(fullName, options);
          },
          layoutFor: function layoutFor(name, owner, options) {
            var templateFullName = 'template:components/' + name;
            return owner.lookup(templateFullName, options);
          }
        }));

        var originalCreate = TemplateCompiler.create;
        TemplateCompiler.create = function (options) {
          var owner = getOwner(options);
          var compiler = originalCreate.apply(undefined, arguments);
          var compileTimeLookup = compiler.resolver;
          var runtimeResolver = compileTimeLookup.resolver;

          // setup our reference capture system
          runtimeResolver.builtInHelpers['-merge-attrs'] = mergeAttributesHelper;

          var AttributeTracker = function () {
            function AttributeTracker(environment, element, attributeName, reference) {
              _classCallCheck(this, AttributeTracker);

              this._environment = environment;
              this._attribute = environment.attributeFor(element, attributeName, false);
              this._reference = reference;
              this.tag = reference.tag;
              this.lastRevision = this.tag.value();
            }

            _createClass(AttributeTracker, [{
              key: 'set',
              value: function set(dom) {
                this._attribute.set(dom, this._reference.value(), this._environment);
                this.lastRevision = this.tag.value();
              }
            }, {
              key: 'update',
              value: function update() {
                if (!this.tag.validate(this.lastRevision)) {
                  this._attribute.update(this._reference.value(), this._environment);
                  this.lastRevision = this.tag.value();
                }
              }
            }]);

            return AttributeTracker;
          }();

          runtimeResolver.builtInModifiers = Ember.assign({}, runtimeResolver.builtInModifiers);
          runtimeResolver.builtInModifiers._splattributes = {
            create: function create(element, args, scope, dom) {
              var environment = owner.lookup('service:-glimmer-environment');
              var domBuilder = clientBuilder(environment, {});
              domBuilder.constructing = element;

              var _args$capture = args.capture(),
                  positional = _args$capture.positional;

              var invocationAttributesReference = positional.at(0);
              var invocationAttributes = invocationAttributesReference.value();
              var attributeNames = invocationAttributes ? Object.keys(invocationAttributes) : [];
              var dynamicAttributes = {};
              var references = [];

              for (var i = 0; i < attributeNames.length; i++) {
                var attributeName = attributeNames[i];
                var ref = invocationAttributesReference.get(attributeName);
                dynamicAttributes[attributeName] = new AttributeTracker(environment, element, attributeName, ref);
                references.push(ref);
              }

              return {
                references: references,
                dynamicAttributes: dynamicAttributes,
                dom: dom,
                domBuilder: domBuilder,
                environment: environment
              };
            },
            getTag: function getTag(_ref3) {
              var references = _ref3.references;

              return combineTagged(references);
            },
            install: function install(bucket) {
              var dynamicAttributes = bucket.dynamicAttributes,
                  domBuilder = bucket.domBuilder;


              for (var name in dynamicAttributes) {
                var attribute = dynamicAttributes[name];
                attribute.set(domBuilder);
              }
            },
            update: function update(bucket) {
              var dynamicAttributes = bucket.dynamicAttributes;


              for (var name in dynamicAttributes) {
                var attribute = dynamicAttributes[name];
                attribute.update();
              }
            },
            getDestructor: function getDestructor() {}
          };

          // setup our custom attribute bindings directly from the references passed in
          var ORIGINAL_LOOKUP_COMPONENT_DEFINITION = runtimeResolver._lookupComponentDefinition;
          var manager = null;
          runtimeResolver._lookupComponentDefinition = function () {
            // call the original implementation
            var definition = ORIGINAL_LOOKUP_COMPONENT_DEFINITION.apply(this, arguments);

            if (definition && manager) {
              definition.manager = manager;
              return definition;
            }

            if (definition) {
              var Manager = definition.manager.constructor;
              manager = definition.manager = new Manager();

              var ORIGINAL_DID_CREATE_ELEMENT = manager.didCreateElement;
              manager.didCreateElement = function (bucket, element, operations) {
                ORIGINAL_DID_CREATE_ELEMENT.apply(this, arguments);
                var args = bucket.args;

                if (args.has('__ANGLE_ATTRS__')) {
                  var angleAttrs = args.get('__ANGLE_ATTRS__');
                  // this use of value is OK because the set of keys isn't allowed to change dynamically
                  var snapshot = angleAttrs.value();
                  if (snapshot) {
                    for (var attributeName in snapshot) {
                      var attributeReference = angleAttrs.get(attributeName);
                      operations.setAttribute(attributeName, attributeReference, false, null);
                    }
                  }
                }
              };
            }

            return definition;
          };

          return compiler;
        };

        return registry;
      }
    });
  } else if (true) {
    Application.reopenClass({
      buildRegistry: function buildRegistry() {
        var registry = this._super.apply(this, arguments);

        var factoryForMethodName = 'factoryFor';
        if (false) {
          factoryForMethodName = Ember.__loader.require('container').FACTORY_FOR;
        }

        registry.register('component-lookup:main', Ember.Object.extend({
          componentFor: function componentFor(name, owner, options) {
            var fullName = 'component:' + name;
            return owner[factoryForMethodName](fullName, options);
          },
          layoutFor: function layoutFor(name, owner, options) {
            var templateFullName = 'template:components/' + name;
            return owner.lookup(templateFullName, options);
          }
        }));
        var Environment = registry.resolve('service:-glimmer-environment');
        var ORIGINAL_ENVIRONMENT_CREATE = Environment.create;
        if (!Environment.create.__IS_ANGLE_BRACKET_PATCHED__) {
          Environment.create = function () {
            var environment = ORIGINAL_ENVIRONMENT_CREATE.apply(this, arguments);
            var installedCustomDidCreateElement = false;

            environment.builtInHelpers['-merge-attrs'] = mergeAttributesHelper;

            var AttributeTracker = function () {
              function AttributeTracker(element, attributeName, reference) {
                _classCallCheck(this, AttributeTracker);

                this._element = element;
                this._attribute = environment.attributeFor(element, attributeName, false);
                this._reference = reference;
                this.tag = reference.tag;
                this.lastRevision = this.tag.value();
              }

              _createClass(AttributeTracker, [{
                key: 'set',
                value: function set() {
                  this._attribute.setAttribute(environment, this._element, this._reference.value());
                  this.lastRevision = this.tag.value();
                }
              }, {
                key: 'update',
                value: function update() {
                  if (!this.tag.validate(this.lastRevision)) {
                    this._attribute.updateAttribute(environment, this._element, this._reference.value());
                    this.lastRevision = this.tag.value();
                  }
                }
              }]);

              return AttributeTracker;
            }();

            environment.builtInModifiers._splattributes = {
              create: function create(element, args, scope, dom) {
                var positional = true ? args.capture().positional : args.positional;
                var invocationAttributesReference = positional.at(0);
                var invocationAttributes = invocationAttributesReference.value();
                var attributeNames = invocationAttributes ? Object.keys(invocationAttributes) : [];
                var dynamicAttributes = {};
                var references = [];

                for (var i = 0; i < attributeNames.length; i++) {
                  var attributeName = attributeNames[i];
                  var ref = invocationAttributesReference.get(attributeName);
                  dynamicAttributes[attributeName] = new AttributeTracker(element, attributeName, ref);
                  references.push(ref);
                }

                return {
                  references: references,
                  dynamicAttributes: dynamicAttributes,
                  dom: dom,
                  environment: environment
                };
              },
              getTag: function getTag(_ref4) {
                var references = _ref4.references;

                return combineTagged(references);
              },
              install: function install(bucket) {
                var dynamicAttributes = bucket.dynamicAttributes;


                for (var name in dynamicAttributes) {
                  var attribute = dynamicAttributes[name];
                  attribute.set();
                }
              },
              update: function update(bucket) {
                var dynamicAttributes = bucket.dynamicAttributes;


                for (var name in dynamicAttributes) {
                  var attribute = dynamicAttributes[name];
                  attribute.update();
                }
              },
              getDestructor: function getDestructor() {}
            };

            var originalGetComponentDefinition = environment.getComponentDefinition;
            environment.getComponentDefinition = function () {
              var definition = originalGetComponentDefinition.apply(this, arguments);

              if (!installedCustomDidCreateElement && definition) {
                installedCustomDidCreateElement = true;

                var manager = definition.manager;


                var ORIGINAL_DID_CREATE_ELEMENT = manager.didCreateElement;
                manager.didCreateElement = function (bucket, element, operations) {
                  ORIGINAL_DID_CREATE_ELEMENT.apply(this, arguments);
                  var args = bucket.args;


                  if (false) {
                    args = args.namedArgs;
                  }

                  // on < 2.15 `namedArgs` is only present when there were arguments
                  if (args && args.has('__ANGLE_ATTRS__')) {
                    var attributeReferences = args.get('__ANGLE_ATTRS__');
                    var snapshot = attributeReferences.value();
                    if (snapshot) {
                      var names = Object.keys(snapshot);
                      for (var i = 0; i < names.length; i++) {
                        var attributeName = names[i];
                        var attributeReference = attributeReferences.get(attributeName);

                        operations.addDynamicAttribute(element, attributeName, attributeReference, false, null);
                      }
                    }
                  }
                };
              }

              return definition;
            };

            return environment;
          };
          Environment.create.__IS_ANGLE_BRACKET_PATCHED__ = true;
        }

        return registry;
      }
    });
  } else {
    // Based heavily on https://github.com/mmun/ember-component-attributes
    Component.reopen({
      __ANGLE_ATTRS__: computed({
        set: function set(key, value) {
          var invocationAttributes = value.invocationAttributes,
              attrSplat = value.attrSplat;


          var combinedAttributes = Ember.assign({}, invocationAttributes, attrSplat);

          if (this.tagName === '') {
            return combinedAttributes;
          }

          var attributes = Object.keys(combinedAttributes);
          var attributeBindingsOverride = [];

          for (var i = 0; i < attributes.length; i++) {
            var attribute = attributes[i];

            attributeBindingsOverride.push('__ANGLE_ATTRS__.' + attribute + ':' + attribute);
          }

          if (this.attributeBindings) {
            var attributeBindings = this.attributeBindings.filter(function (microsyntax) {
              // See https://github.com/emberjs/ember.js/blob/6a6f279df3b1a0979b5fd000bf49cd775c720f01/packages/ember-glimmer/lib/utils/bindings.js#L59-L73
              var colonIndex = microsyntax.indexOf(':');
              var attribute = colonIndex === -1 ? microsyntax : microsyntax.substring(colonIndex + 1);

              return attributes.indexOf(attribute) === -1;
            });

            this.attributeBindings = attributeBindingsOverride.concat(attributeBindings);
          } else {
            this.attributeBindings = attributeBindingsOverride;
          }

          return combinedAttributes;
        }
      })
    });
  }
})(); /* globals Ember */
/* eslint-disable ember/new-module-imports */