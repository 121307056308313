define('bgr-ember-json-api/services/meta-store/index', ['exports', 'bgr-ember-json-api/utils', 'bgr-ember-json-api/services/meta-store/meta-array'], function (exports, _utils, _metaArray) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var MAP = Ember.Object.create();

  var metaStoreId = 0;

  exports.default = Ember.Service.extend({
    /**
     * Services
     */

    store: Ember.inject.service(),

    /**
     * Private methods
     */

    addMeta: function addMeta(resource, relationshipKey, relationshipId, relationshipMetaAttributes) {
      return this.addMetaToMetaArray(resource, relationshipKey, relationshipId, relationshipMetaAttributes, this.ensureMetaArray(resource, relationshipKey));
    },
    addMetaToMetaArray: function addMetaToMetaArray(resource, relationshipKey, relationshipId, relationshipMetaAttributes, metaArray) {
      var meta = metaArray.findMeta(relationshipId);

      if (!meta) {
        meta = this.createMeta({
          relationshipId: relationshipId,
          relationshipKey: relationshipKey,
          relationshipMetaAttributes: relationshipMetaAttributes,
          resource: resource
        });

        metaArray.pushObject(meta);
      }

      return meta;
    },
    buildMetaArrayKey: function buildMetaArrayKey(resource, relationshipKey) {
      return resource.constructor.modelName + ':' + this.metaStoreIdForResource(resource) + ':' + relationshipKey;
    },
    createMeta: function createMeta(_ref) {
      var relationshipId = _ref.relationshipId,
          relationshipKey = _ref.relationshipKey,
          relationshipMetaAcknowledgedAttributes = _ref.relationshipMetaAcknowledgedAttributes,
          relationshipMetaAttributes = _ref.relationshipMetaAttributes,
          resource = _ref.resource;

      var relationship = (0, _utils.getRelationship)(resource, relationshipKey);

      return relationship.options.meta.create({
        _acknowledgedAttributes: relationshipMetaAcknowledgedAttributes,
        _attributes: relationshipMetaAttributes,
        _relationshipId: relationshipId,
        _relationshipKey: relationshipKey,
        _resource: resource
      });
    },
    createMetaArray: function createMetaArray(resource, relationshipKey) {
      var content = Ember.A();
      var metaArrayKey = this.buildMetaArrayKey(resource, relationshipKey);
      var metaArray = _metaArray.default.create({
        content: content,
        _relationshipKey: relationshipKey,
        _resource: resource
      });

      MAP.set(metaArrayKey, metaArray);

      return metaArray;
    },
    destroyMeta: function destroyMeta(resource, relationshipKey, relationshipId) {
      var metaArray = this.findMetaArray(resource, relationshipKey);

      if (metaArray) {
        var meta = metaArray.findMeta(relationshipId);

        if (meta) {
          metaArray.removeObject(meta);
          meta.destroy();
        }
      }

      return this;
    },
    destroyMetaArray: function destroyMetaArray(resource, relationshipKey) {
      var metaArray = this.findMetaArray(resource, relationshipKey);

      if (metaArray) {
        metaArray.forEach(function (meta) {
          return meta.destroy();
        });
        metaArray.clear();
      }

      return this;
    },
    ensureMetaArray: function ensureMetaArray() {
      return this.findMetaArray.apply(this, arguments) || this.createMetaArray.apply(this, arguments);
    },
    findMetaArray: function findMetaArray() {
      return MAP.get(this.buildMetaArrayKey.apply(this, arguments));
    },
    mergeMetaToMetaArray: function mergeMetaToMetaArray(resource, relationshipKey, relationshipId, relationshipMetaAcknowledgedAttributes, metaArray) {
      var meta = metaArray.findMeta(relationshipId);

      if (meta) {
        meta.set('_acknowledgedAttributes', relationshipMetaAcknowledgedAttributes);
      } else {
        meta = this.createMeta({
          relationshipId: relationshipId,
          relationshipKey: relationshipKey,
          relationshipMetaAcknowledgedAttributes: relationshipMetaAcknowledgedAttributes,
          resource: resource
        });

        metaArray.pushObject(meta);
      }

      return meta;
    },
    metaStoreIdForResource: function metaStoreIdForResource(resource) {
      var resourceMetaStoreId = resource.get('META_STORE_ID');

      if (!resourceMetaStoreId) {
        metaStoreId++;

        resourceMetaStoreId = metaStoreId.toString();

        resource.set('META_STORE_ID', resourceMetaStoreId);
      }

      return resourceMetaStoreId;
    },
    resolveOrReject: function resolveOrReject(promise, relationshipId) {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        promise.then(function (metaArray) {
          var meta = metaArray.findMeta(relationshipId);

          if (meta) {
            resolve(meta);
          } else {
            reject();
          }
        });
      });
    },


    /**
     * Public methods
     */

    findAllMeta: function findAllMeta(resource, relationshipKey) {
      var _this = this;

      var modelName = resource.constructor.modelName;
      var adapter = this.get('store').adapterFor(modelName);
      var url = [adapter.buildURL(modelName, resource.get('id')), 'relationships', relationshipKey].join('/');

      return adapter.ajax(url).then(function (payload) {
        var metaArray = _this.ensureMetaArray(resource, relationshipKey);

        payload.data.forEach(function (relationshipData) {
          _this.mergeMetaToMetaArray(resource, relationshipKey, relationshipData.id.toString(), (0, _utils.camelizeObject)(relationshipData.meta), metaArray);
        });

        return metaArray;
      });
    },
    findMeta: function findMeta(resource, relationshipKey, relationshipId) {
      return this.resolveOrReject(this.findAllMeta(resource, relationshipKey), relationshipId);
    },
    peekAllMeta: function peekAllMeta(resource, relationshipKey) {
      return this.ensureMetaArray(resource, relationshipKey);
    },
    peekMeta: function peekMeta(resource, relationshipKey, relationshipId) {
      return this.peekAllMeta(resource, relationshipKey).findMeta(relationshipId);
    }
  });
});