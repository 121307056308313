define('bgr-ember-json-api/services/meta-store/meta-model', ['exports', 'bgr-ember-json-api/utils'], function (exports, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    /**
     * Attributes acknowledged by the server.
     *
     * @type {Object}
     */

    _acknowledgedAttributes: null,

    /**
     * Attributes set at runtime.
     *
     * @type {Object}
     */

    _attributes: null,

    /**
     * Id of the relationship record linked to this meta record.
     *
     * @type {String}
     */

    _relationshipId: null,

    /**
     * Key of the relationship linked to this meta record.
     *
     * @type {String}
     */

    _relationshipKey: null,

    /**
     * Resource record linked to this meta record.
     *
     * @type {Model}
     */

    _resource: null,

    /**
     * Current snapshot.
     * A snapshot is made right before a save and committed after the save was successful.
     *
     * @type {Object}
     */

    _snapshot: null,

    /**
     * Array that contains all attribute names.
     *
     * @return {Array}
     */

    attributes: Ember.computed(function () {
      var attributes = [];

      this.constructor.eachComputedProperty(function (name, meta) {
        if (meta.isAttribute) {
          attributes.push(name);
        }
      });

      return attributes;
    }),

    /**
     * Indicates if the meta record has dirty attributes.
     *
     * @return {Boolean}
     */

    hasDirtyAttributes: Ember.computed('_acknowledgedAttributes', '_attributes', 'isNew', function () {
      if (this.get('isNew')) {
        return true;
      }

      var acknowledgedAttributes = this.get('_acknowledgedAttributes');
      var attributes = this.get('_attributes');

      return Object.keys(attributes).some(function (key) {
        return attributes[key] !== acknowledgedAttributes[key];
      });
    }),

    /**
     * Indicates if the meta record is new.
     *
     * @return {Boolean}
     */

    isNew: Ember.computed('_acknowledgedAttributes', function () {
      return Object.keys(this.get('_acknowledgedAttributes')).length === 0;
    }),

    /**
     * Init hook.
     *
     * @param  {Array} args
     * @return {null}
     */

    init: function init() {
      this._super.apply(this, arguments);

      if (!this._acknowledgedAttributes) {
        this._acknowledgedAttributes = {};
      }

      if (!this._attributes) {
        this._attributes = {};
      }
    },


    /**
     * Commit the meta record.
     *
     * @param  {Object} acknowledgedAttributes Acknowledged attributes.
     * @return {Meta}                          Meta record.
     */

    _commit: function _commit(acknowledgedAttributes) {
      this.setProperties({
        _acknowledgedAttributes: acknowledgedAttributes,
        _attributes: {},
        _snapshot: null
      });

      return this;
    },


    /**
     * Rollback a specific attribute by name.
     *
     * @param  {String} name Attribute name.
     * @return {Meta}        Meta record.
     */

    rollbackAttribute: function rollbackAttribute(name) {
      var attributes = (0, _utils.copy)(this.get('_attributes'));

      Reflect.deleteProperty(attributes, name);

      this.set('_attributes', attributes);

      this.notifyPropertyChange(name);

      return this;
    },


    /**
     * Rollback all attributes.
     *
     * @return {Meta} Meta record.
     */

    rollbackAttributes: function rollbackAttributes() {
      var _this = this;

      this.set('_attributes', {});

      this.get('attributes').forEach(function (name) {
        return _this.notifyPropertyChange(name);
      });

      return this;
    },


    /**
     * Save the meta record.
     *
     * @return {Promise} Promise resolves with meta record.
     */

    save: function save() {
      var _this2 = this;

      var saveOptions = { adapterOptions: { metaRelationshipId: this.get('_relationshipId') } };

      this.set('_snapshot', this.toJSON());

      return this.get('_resource').saveRelationship(this.get('_relationshipKey'), saveOptions).then(function () {
        return _this2;
      });
    },


    /**
     * Create a JSON representation of the meta record.
     *
     * @return {Object} JSON representation.
     */

    toJSON: function toJSON() {
      return this.getProperties(this.get('attributes'));
    }
  });
});