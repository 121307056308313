define('bgr-ember-modal/helpers/bgr-modal-action', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var VALID_ACTIONS = ['add', 'alert', 'confirm', 'hide', 'hide-all', 'hide-upper', 'show'];

  var VALID_ACTIONS_STRING = VALID_ACTIONS.join(', ');

  exports.default = Ember.Helper.extend({
    bgrModal: Ember.inject.service(),

    compute: function compute(params) {
      var _this = this;

      var action = params[0];

      (true && !(VALID_ACTIONS.includes(action)) && Ember.assert('The provided action should be one of the following: ' + VALID_ACTIONS_STRING + '.', VALID_ACTIONS.includes(action)));


      return function () {
        var _get;

        return (_get = _this.get('bgrModal'))[Ember.String.camelize(action)].apply(_get, _toConsumableArray(params.slice(1)));
      };
    }
  });
});