define('bgr-ember-cropper/components/bgr-cropper', ['exports', 'bgr-ember-cropper/mixins/bgr-cropper-resize-support', 'bgr-ember-cropper/templates/components/bgr-cropper'], function (exports, _bgrCropperResizeSupport, _bgrCropper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var _window = window,
      Cropper = _window.Cropper;


  var image = document.createElement('img');
  var cropper = new Cropper(image);

  var CROPPER_OPTIONS = Object.keys(cropper.options);
  var OBSERVABLE_CROPPER_OPTIONS = CROPPER_OPTIONS.concat(['src']);

  OBSERVABLE_CROPPER_OPTIONS.splice(OBSERVABLE_CROPPER_OPTIONS.indexOf('data'), 1);

  exports.default = Ember.Component.extend(_bgrCropperResizeSupport.default, {
    /**
     * Props
     */

    height: null,
    src: null,
    width: null,

    /**
     * State
     */

    attributeBindings: ['style'],
    classNameBindings: ['isActive:is-active:is-inactive'],
    classNames: ['cropper'],
    instance: null,
    isActive: false,
    layout: _bgrCropper.default,

    /**
     * Computed
     */

    style: Ember.computed('height', 'width', function () {
      var _this = this;

      var style = '';

      ['height', 'width'].forEach(function (key) {
        var value = _this.get(key);

        if (value) {
          style += key + ':' + value + ';';
        }
      });

      return Ember.String.htmlSafe(style);
    }),

    /**
     * Hooks
     */

    init: function init() {
      this._super.apply(this, arguments);

      this.readyHandler = Ember.run.bind(this, this.readyHandler);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      (true && !(this.get('src')) && Ember.assert('The \'src\' option is required.', this.get('src')));


      this.addEventListeners();
      this.createInstance();
      this.addOptionObservers();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.removeEventListeners();
      this.destroyInstance();
    },


    /**
     * Observers
     */

    styleObserver: Ember.observer('style', function () {
      var _this2 = this;

      Ember.run.next(function () {
        return _this2.resizeInstance();
      });
    }),

    /**
     * Methods
     */

    addEventListeners: function addEventListeners() {
      var image = this.get('element.firstChild');

      image.addEventListener('ready', this.readyHandler);
    },
    addOptionObservers: function addOptionObservers() {
      var _this3 = this;

      OBSERVABLE_CROPPER_OPTIONS.forEach(function (cropperOption) {
        _this3.addObserver(cropperOption, _this3, function () {
          _this3.destroyInstance();
          Ember.run.next(function () {
            return _this3.createInstance();
          });
        });
      });
    },
    createInstance: function createInstance() {
      var image = this.get('element.firstChild');
      var options = this.getOptions({ responsive: false });
      var instance = new Cropper(image, options);

      this.set('instance', instance);
    },
    destroyInstance: function destroyInstance() {
      var instance = this.get('instance');

      if (instance) {
        instance.destroy();
      }

      this.setProperties({
        instance: null,
        isActive: false
      });
    },
    getOptions: function getOptions(overwrites) {
      var _this4 = this;

      var options = {};

      CROPPER_OPTIONS.forEach(function (cropperOption) {
        var value = _this4.get(cropperOption);

        if (typeof value !== 'undefined') {
          options[cropperOption] = value;
        }
      });

      Object.assign(options, overwrites);

      return options;
    },
    readyHandler: function readyHandler() {
      this.set('isActive', true);
    },
    removeEventListeners: function removeEventListeners() {
      var image = this.get('element.firstChild');

      image.removeEventListener('ready', this.readyHandler);
    },
    resizeInstance: function resizeInstance() {
      this.get('instance').resize();
    }
  });
});